import Lenis from '@studio-freight/lenis'

// detect mobile
let mobile = false
if (window.innerWidth < 1024) {
    mobile = true
}

let lenisMain;
const chatWindow = document.getElementById('messages');
if (!mobile) {

    lenisMain = new Lenis({
        duration: 0.6,
        easing: (x) => (1 - Math.pow(1 - x, 4)),
        direction: "vertical",
        gestureDirection: "vertical",
        smooth: true,
        smoothTouch: true,
        touchMultiplier: 2,
        autoResize: true,
        prevent: (node) => node.classList.contains('search-results-container')
    });


    function raf(time) {
        lenisMain.raf(time)
        requestAnimationFrame(raf)
    }
    requestAnimationFrame(raf)

    // lenis scroll to anchor global function
    $('.scrollToAnchor').on('click', function (e) {
        e.preventDefault();
        lenisMain.scrollTo('#' + this.dataset.target, {
            offset: window.innerWidth < 1200 ? (parseInt(this.dataset.offset)) / 2.5 : parseInt(this.dataset.offset),
            duration: 2,
            easing: (x) => (x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2)
        });
    })
}

export { lenisMain, mobile }